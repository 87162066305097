import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.activePerson)?_c(VSheet,[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-phone")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.activePerson.mobile))]),_c(VListItemSubtitle,[_vm._v("Mobile")]),_c('div')],1),_c(VListItemAction)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-sheet v-if="activePerson">
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-phone</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ activePerson.mobile }}</v-list-item-title>
        <v-list-item-subtitle>Mobile</v-list-item-subtitle>
        <div>
        </div>
      </v-list-item-content>
      <v-list-item-action>
<!--        <app-btn fab x-small>-->
<!--          <v-icon>mdi-pencil</v-icon>-->
<!--        </app-btn>-->
<!--        <app-btn fab>-->
<!--          <v-icon>mdi-pencil</v-icon>-->
<!--        </app-btn>-->
      </v-list-item-action>
    </v-list-item>
  </v-sheet>
</template>

<script>
import {mapState} from "vuex";
import {isAuthorised} from "@/util/helpers";

export default {
  name: "AboutOverviewPage",
  computed: {
    ...mapState("person",["activePerson","loggedInPerson"]),
    ...mapState("user", ["accountRoles"]),
    isEditable() {
      return this.activePerson._id === this.loggedInPerson._id || isAuthorised(this.accountRoles,["rootAdmin","admin"])
    }
  },

}
</script>

<style scoped>

</style>